import '../styles/pages/old.scss';
import '../styles/pages/corp.scss';
import * as React from "react"
import {HeadFC, navigate} from "gatsby"
import {useState, useRef, useEffect} from "react";
import {toast, ToastContainer} from "react-toastify";
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import useAnalytics from "../hooks/useAnalytics";

const CorpPage = () => {
    const [email, setEmail] = useState('');
    const {initAnalytics, saveEmail} = useAnalytics();

    useEffect(() => {
        initAnalytics()
    }, []);

    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const onFormSubmit = async () => {
        if (email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            await saveEmail(email);
            // fbq("track", "Subscribe");
            localStorage.setItem('email', email);
            navigate('/solutions/education');
        } else {
            toast('Invalid e-mail address', {
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }
    }

    return (
        <main>

            <div className={`blurFormWrap active`}>
                <div className="blurFormOverlay"></div>
                <div className="blurForm">
                    <div className="blurFormTitle">Let's customize the aeer app for you</div>
                    <div className="blurForm">
                        <div className={`solutionsEmailInput`}>
                            <p className="solutionsEmailTitle">Enter your email to continue</p>
                            <label htmlFor="email">Email</label>
                            <input id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                placeholder="Please enter your email address"
                                autoComplete="off"
                                ref={inputRef} />
                            <button onClick={() => onFormSubmit()} className="solutionsBtn" id="pageEmailSignUpBtn">Sign Up</button>
                        </div>
                        <p className="solutionsSecure static">Your information is 100% secure. AEER Platform does not share your personal information with third parties</p>
                    </div>
                </div>
            </div>

            <ToastContainer/>
        </main>
    )
}

export default CorpPage

export const Head: HeadFC = () => <title>AEER Platform</title>
